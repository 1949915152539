@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your CSS file */
@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulseEffect {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.gradient-background {
  /* Define your gradient colors and direction */
  background-size: 200% 200%;

  /* Apply the animations */
  animation: moveGradient 4s ease infinite, pulseEffect 4s ease infinite;
}

.vectors-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: darken(0.5);
}

.grain {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensures clicks pass through */
  background-image: url("https://arc.net/noise-light.png");
  opacity: 0.5;
  background-repeat: repeat;
}

@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: var(--completed-percentage);
  }
}

.progress-bar-inner {
  animation: fill 2s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOver {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-slideIn {
  animation: slideIn 0;
}

#grain {
  background: url("./assets/Grain.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 1000;
  pointer-events: none;
  opacity: 0.5;
}